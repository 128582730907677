import InforItem from "./components/inforItem";
import activityButton from "./components/activityButton";
import InactiveButton from "./components/InactiveButton";
import { getProductInfo, getTopInfo } from "./api";

export default {
  components: {
    InforItem,
    activityButton,
    InactiveButton
  },
  data() {
    return {
      percent: 30,
      roundsTtle: "第一轮",
      animate: false,
      topTenList: [],
      items: [],
      InfoImg: "",
      unFinishNum: "",
      orderNum: "",
      targetNum: "",
      rate: 0,
      index: 0,
      timer: null,
      stepNum: [],
      dataList: []

    };
  },
  filters: {
    // 数据每个三位加，
    num: function(value) {
      // if(!value) return '0.00'
      value = value.toFixed(0);
      var intPart = Math.trunc(value);// 获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
      var floatPart = ""; // 预定义小数部分
      var value2Array = value.split(".");
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString(); // 拿到小数部分
        if (floatPart.length === 1) { // 补0,实际上用不着
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    }

  },
  created() {
    // setInterval(this.scroll,1000)
  },
  watch: {
    roundsTtle: function(value, oldVlue) {
    }
  },
  mounted() {
    this.getInfo();
    this.timer = setInterval(() => {
      setTimeout(this.getPmInfo, 0);
    }, 1000);
    this.keyDown();
  },
  methods: {
    showkey() {
      document.onkeydown = function() {
        if (window.event.keyCode == 40) {
          window.event.returnValue = false;
        }
      };
    },
    //数字转换中文
    changeNum(num) {

        let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
        let unit = ["", "十", "百", "千", "万"];
        num = parseInt(num);
        let getWan = (temp) => {
          let strArr = temp.toString().split("").reverse();
          let newNum = "";
          for (var i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
          }
          return newNum;
        };
        let overWan = Math.floor(num / 10000);
        let noWan = num % 10000;
        if (noWan.toString().length < 4) {
          noWan = "0" + noWan;
        }
        return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);

    },
    // 监听键盘
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 = e || event || window.event;
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //左
        if (e1 && e1.keyCode == 40) {
          // 按下下箭头
          this.index = this.index + 1;
          if (this.index >= this.dataList.length) {
            this.$message.warning("没有更多活动了，尽情期待");
            return;
          } else {
            this.getInfo();
            this.roundsTtle = "第" + this.changeNum(Number(this.index)+ 1)  + "轮";
          }
          // this.showkey()

        }
      };
    },
    //  获取商品图片
    async getInfo() {
      let data = {
        actName: "测试活动"
      };
      await getProductInfo(data).then(res => {
        this.dataList = res.data.list;
        if (res.data.list[this.index]) {
          this.InfoImg = res.data.list[this.index].imgUrl;
          this.productCode = res.data.list[this.index].code;
          this.getPmInfo();
        }
      });
    },
    // 获取排名信息
    getPmInfo() {
      let dataa = {
        actPrdId: this.productCode
      };
      getTopInfo(dataa).then(res => {
        //50订单列表
        this.items = res.data.msg;
        //top10列表
        this.topTenList = res.data.top10;
        //距达成目标
        if(res.data.data.unFinishNum){
          this.unFinishNum = this.$options.filters["num"](res.data.data.unFinishNum);
          this.unFinishNum = (this.unFinishNum).toString().split("");
        }
        //目标值
        this.targetNum = res.data.data.targetNum;
        //已下单
        this.orderNum = res.data.data.finishNum;
        // 节点获取
        if( res.data.data.stepNum) {
          this.stepNum = res.data.data.stepNum.split("-");
        }
        // 完成率
        if(this.orderNum && res.data.data.targetNum) {
          this.rate = parseInt((this.orderNum / res.data.data.targetNum) * 100);
        }
      });
    }

  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
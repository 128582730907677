import http from "@/utils/request";

// post
export function getProductInfo(data) {
  return http({
    method: "post",
    url: "/temreport/productList.nd",
    data
  });
}

export function getTopInfo(data) {
  return http({
    method: "post",
    url: "/temreport/zonghe.nd",
    data
  });
}
